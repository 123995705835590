import { decimalNumber } from "../../../../../helpers/Functions";

const SellarPenalityDetails = ({
  amount,
  currenncy,
  inputValue,
  checkedValue,
  onChange,
  confirmation,
  sellarName,
  deduct_from_wallet,
}: any) => {
  let DisabledRadioBtn: any =
    confirmation || Number(inputValue) > Number(amount);
  console.log(checkedValue, "checkedValue");
  return (
    <div
      className={`seller-details md:min-h-[3.125rem] md:pl-5 md:p-[.9375rem] p-2.5 ${
        confirmation && "!pb-0"
      } `}
    >
      <div className="flex max-md:flex-wrap gap-3.5 items-center">
        <p className="text-sm12 font-medium">
          Seller: <span>{sellarName}</span>{" "}
        </p>
        <p className="text-sm12 font-medium">
          Wallet balance:{" "}
          <span>
            {currenncy}
            {decimalNumber(amount)}
          </span>
        </p>
        <div className="flex  items-center">
          <p className="text-sm12 font-medium">Deduct from wallet?</p>
          {/* <label
              className={`relative inline-flex items-center cursor-pointer ${
                DisabledRadioBtn && `disabled`
              } [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none ${
                DisabledRadioBtn && "pointer-events-none"
              }`}
            >
              <input
                type="checkbox"
                name="deduct_from_wallet"
                value=""
                className="sr-only peer"
                checked={checkedValue}
                onChange={(e: any) => onChange(e)}
              />
              <div
                className={`${
                  DisabledRadioBtn
                    ? "pointer-events-none bg-gray-100 peer-checked:after:bg-gray-400 after:bg-gray-400"
                    : "bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                } w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8`}
              ></div>
            </label> */}
          {!confirmation ? (
            <input
              className={`form-check-input h-[.8125rem] w-[.8125rem] rounded-sm bg-gray-100 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus-within:border-indigo-500 focus:ring-0 focus:ring-offset-0 checked:group-hover:border-indigo-500 checked:!bg-indigo-500"
                `}
              name="deduct_from_wallet"
              type="checkbox"
              value=""
              checked={checkedValue}
              onChange={(e: any) => onChange(e)}
            />
          ) : (
            <p className="text-sm12 font-medium">
              &nbsp;{deduct_from_wallet ? "Yes" : "No"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellarPenalityDetails;
