import { IKTSVG } from "components/ui/IKTSVG";
import moment from "moment";
import { getSalesEventDateRange } from "pages/inventory/core/_functions";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext } from "react";
import notifyIcon from "../../../assets/media/icons/other_icons/notifyIcon.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockIcon from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import LocationIcon from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import { DashboardContext } from "../core/DashboardProvider";

const OnsalesCard = ({ performer, scrollToTop }: any) => {
  const layout = useContext(DashboardContext);

  const handleCardClick = (performer: any) => {
    layout.setSelectedOnSalesPerformer({ performer, status: true });
    scrollToTop("onsalesbody");
  };

  return (
    <div className="singleOrder w-full px-2.5 h-full">
      <div
        className="border rounded p-[.9375rem] h-full group hover:border-indigo-500 flex flex-col "
        // data-te-toggle="offcanvas"
        // data-te-target="#onsales"
        // aria-controls="onsalesLabel"
        onClick={() => handleCardClick(performer)}
      >
        <div className="title mb-3 relative pr-[1.125rem]">
          <h3 className="text-sm13 font-medium leading-4">
            {performer?.performer_name}
            {performer?.events?.length > 1 &&
              ` (${performer?.events?.length} events)`}
          </h3>
          {performer?.starting_soon === 1 && (
            <div className="absolute top-0 right-0">
              <IKTSVG
                path={notifyIcon}
                svgClassName="w-[.5625rem] h-2.5"
                className="w-[1.125rem] h-[1.125rem] bg-rose-500 flex items-center justify-center rounded-full cursor-pointer hover:bg-indigo-500"
                dataTooltipID={`onsales-card-${performer?.performer_name}-${performer?.start_event_date}`}
              />
              <TooltipPortal
                id={`onsales-card-${performer?.performer_name}-${performer?.start_event_date}`}
                content={`Starting soon`}
                className="!bg-white !py-0.5 !px-1.5 font-medium !text-xxs z-[1045] mt-1.5 text-center !opacity-100"
              />
            </div>
          )}
        </div>
        <div className="eventInfo flex flex-wrap gap-x-2.5 gap-y-[.3125rem] mb-3">
          <div className="singleInfo">
            <p className="flex items-start text-xs text-gray-500 font-medium leading-[.9375rem]">
              <IKTSVG
                path={CalenderSVG}
                className="mr-1.5 mt-0.5"
                svgClassName="w-3 h-3 fill-gray-500 "
              />
              {getSalesEventDateRange(performer)}
              <span className="ml-2 flex items-center">
                <IKTSVG
                  path={ClockIcon}
                  className="mr-1.5"
                  svgClassName="w-3 h-3 fill-gray-500 "
                />
                {moment(performer?.start_event_date).format("HH:mm")}
              </span>
            </p>
          </div>
          <div className="singleInfo">
            <p className="flex items-start text-xs text-gray-500 font-medium leading-[.9375rem]">
              <IKTSVG
                path={LocationIcon}
                className="mr-1.5 mt-0.5"
                svgClassName="w-3 h-3 fill-gray-500 "
              />
              {performer?.venue}
            </p>
          </div>
        </div>

        <div className="eventdetails  p-2.5  bg-gray-100/50 rounded group-hover:bg-indigo-500 group-hover:bg-opacity-[7%] mb-3">
          <div className="-mx-[.3125rem] flex flex-wrap gap-y-2">
            {performer?.pre_sale_date && (
              <div className="singleDetail w-full font-medium px-[.3125rem]">
                <label className="leading-3 text-xxs text-gray-500 mb-[.3125rem] flex">
                  Pre-sale
                </label>
                <h4 className="text-sm12 leading-[.9375rem]">
                  {moment(performer?.pre_sale_date).format(
                    "ddd, DD MMM YY HH:mm"
                  )}
                </h4>
              </div>
            )}
            <div className="singleDetail w-full font-medium px-[.3125rem]">
              <label className="leading-3 text-xxs text-gray-500 mb-[.3125rem] flex">
                On-sale
              </label>
              <h4 className="text-sm12 leading-[.9375rem]">
                {moment(performer?.on_sale_date).format("ddd, DD MMM YY HH:mm")}
              </h4>
            </div>
          </div>
        </div>
        <div className="eventBtn mt-auto">
          <button
            type="button"
            className="leading-4 rounded bg-violet-500 text-sm13 font-medium px-2.5 py-1 text-white group-hover:bg-indigo-500"
          >
            View details
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnsalesCard;
