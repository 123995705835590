/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { KTSVG } from "../KTSVG";

const PublishButton = ({
  children,
  title,
  name,
  handleOnClick,
  isLoading,
  eventLength,
  isActive = false,
}: any) => {
  // let eventLength = layout.events.length > 0;
  const [isPublished, setIsPublished] = useState(true);

  return (
    <button
      type="button"
      name={name}
      className={`${
        eventLength
          ? "pointer-events-none !text-gray-400 bg-gray-100 border-gray-100"
          : "relative z-10 border border-green-600  active:text-white   bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-green-500 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 active:after:opacity-0 after:-z-10"
      } inline-flex items-center group transition font-semibold rounded text-sm13 px-2.5 py-0.5 border ${
        isLoading ? `pointer-events-none text-gray-500` : ``
      } ${
        isActive
          ? "!bg-white !text-green-600  after:hidden hover:!bg-indigo-500 hover:!border-indigo-500 hover:!text-white"
          : "text-white hover:!bg-indigo-500 hover:!border-indigo-500 hover:!text-white"
      }`}
      onClick={(data: any) => {
        setIsPublished((pre: any) => !pre);
        handleOnClick(isPublished);
      }}
      disabled={isLoading ? true : false}
    >
      {isLoading ? (
        <KTSVG
          className="flex items-center justify-center fill-violet-500 transition animate-spin"
          path="other_icons/process.svg"
        />
      ) : (
        ""
      )}
      {title ? title : "PUBLISH LIVE"}
    </button>
  );
};

export default PublishButton;
