import { LayoutContext } from "components/core/LayoutProvider";
import DropDownField from "components/formComponent/DropDownField";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import {
  decimalNumber,
  getProperTicketTypeWithSubticketCombine,
  getTicketTypeValue,
  handleSelectDeselectAll,
  isValidPriceFieldNumber,
  removeCommaFromNumber,
  separateBenefitsAndRestrictions,
  ticketTypeRename,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import moment from "moment";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import notifyIcon from "../../../assets/media/icons/other_icons/notifyIcon.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockIcon from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import LocationIcon from "../../../assets/media/icons/standard_icons/geo_icon.svg";

import MultiDropDownField from "components/formComponent/MultiDropDownField";
import PriceField from "components/formComponent/PriceField";
import TextInput from "components/formComponent/TextInput";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { QUERIES, SYMBOLS } from "helpers/const";
import _ from "lodash";
import { SALES_REDIRECT_ROUTES } from "pages/Sales/core/const";
import { useQuery } from "react-query";
import infoIcon from "../../../assets/media/icons/other_icons/info.svg";
import TickIcon from "../../../assets/media/icons/other_icons/tickcircle.svg";
import { DashboardContext } from "../core/DashboardProvider";
import { getOfferedOrders, orderAction } from "../core/requests";

const CONFIRMATION_INITIAL_STATE = {
  status: false,
  message: "",
  output: "",
};

const LableInput = ({
  label,
  value,
  halfWidth = false,
  highlight = false,
}: any) => {
  return (
    <div
      className={`singleFOrmBLock ${
        halfWidth ? "md:w-1/4" : "md:w-1/2"
      } w-full px-[.4375rem] `}
    >
      <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-[.9375rem]">
        {label}
      </label>
      <div
        className={`!h-6 border-0 px-2.5 py-1 text-xs rounded ${
          !value && "text-gray-400"
        } ${
          highlight
            ? " outline-1 outline outline-indigo-500 bg-white"
            : "bg-gray-100/50"
        }`}
      >
        {value ? value : "—"}
      </div>
    </div>
  );
};

interface OfferTicketType {
  ticket_type: any;
  // quality: any;
  // delivered_by: any;
  // category: any;
  ticket_price: any;
  row: any;
  first_seat: any;
  benefits: any;
  restrictions: any;
  sub_ticket_type: any;
  ticket_section: any;
  deliver_by_date: any;
  order_total: any;
  category: any;
  section: any;
  ticket_currency: any;
}

const INITIAL_TICKET_STATE = {
  ticket_type: "",
  // delivered_by: "",
  ticket_price: "",
  row: "",
  first_seat: "",
  benefits: [],
  restrictions: [],
  sub_ticket_type: "",
  ticket_section: "",
  deliver_by_date: "",
  order_total: "",
  category: "",
  section: "",
  ticket_currency: "",
};

const generatePayload = (object: OfferTicketType, order: any) => {
  const newObject = _.cloneDeep(object);

  const ticketTypeId = newObject.ticket_type?.id;
  const ticket_type =
    ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
      ? "eticket"
      : ticketTypeId;

  const ticketType = ticketTypeId ? ticket_type : newObject.ticket_type;

  return {
    ...newObject,
    category_id: newObject?.category?.id,
    ticket_category: newObject?.category?.name,
    ticket_section: newObject?.section?.name,
    section: newObject?.section?.id,
    // ticket_type: newObject?.ticket_type?.id,
    ticket_type: ticketType,
    ...(ticketType === "eticket" && {
      sub_ticket_type: ticketTypeId ? ticketTypeId : newObject?.sub_ticket_type,
    }),
    ticket_price:
      typeof newObject?.ticket_price === "object"
        ? newObject?.ticket_price?.value
        : newObject?.ticket_price,
    order_total:
      Number(
        typeof newObject?.ticket_price === "object"
          ? newObject?.ticket_price?.value
          : newObject?.ticket_price
      ) * order?.ticket_quantity,
    ticket_currency:
      typeof newObject?.ticket_price === "object"
        ? newObject?.ticket_price?.symbol
        : newObject?.ticket_currency,
    restrictions: [...newObject?.benefits, ...newObject?.restrictions].map(
      (item: any) => item?.name
    ),
    benefits: undefined,

    category: undefined,
  };
};

const OrderOutOfferDetailsModal = ({
  userOptions,
  setOrderOutOfferListData,
  refetchOrderOutOffer,
  scrollToTop,
}: any) => {
  const navigate = useNavigate();
  const layout = useContext(DashboardContext);
  const coreLayout = useContext(LayoutContext);

  const [order, setOrder] = useState(layout.selectedOrderOutOffer?.order);
  const [options, setOptions] = useState<any>();
  const [acceptedOrder, setAcceptedOrder] = useState(false);
  const [confirmation, setConfirmation] = useState(CONFIRMATION_INITIAL_STATE);
  const [sectionsOptions, setSectionsOptions] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [editedAfterConfirmation, setEditedAfterConfirmation] = useState<any>(
    []
  );
  console.log(editedAfterConfirmation, "editedAfterConfirmation");
  const [actionLoader, setActionLoader] = useState(false);
  const [makeoffer, setMakeoffer] = useState(false);

  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const userSpecificData: any = coreLayout?.allowedAccess?.allowed_access?.find(
    (item: any) => item?.id === "user-specific-data"
  )?.status;
  const currentUserId = coreLayout?.authUser?.id;
  const [initialTicketState, setInitialTicketState] =
    useState<OfferTicketType>(INITIAL_TICKET_STATE);
  const [offerTicket, setOfferTicket] =
    useState<OfferTicketType>(INITIAL_TICKET_STATE);

  const orderTotal = useMemo(() => {
    const ticket_price: any = removeCommaFromNumber(
      typeof offerTicket?.ticket_price === "object"
        ? offerTicket?.ticket_price?.value
        : offerTicket?.ticket_price
    );
    return ticket_price * order?.ticket_quantity;
  }, [offerTicket?.ticket_price, order?.ticket_quantity]);

  function getDifferentKeys(obj1: any, obj2: any) {
    return _.reduce(
      obj1,
      (result: any, value, key: any) => {
        if (!_.isEqual(value, obj2[key])) {
          result.push(key);
        }
        return result;
      },
      []
    );
  }

  const handleOrderAction = ({ user_id, status }: any) => {
    setActionLoader(true);
    orderAction({
      status,
      user_id,
      order_id: order?.order_id,
      // team_offer_id: order?.team_offer_id,
      ticket_details:
        makeoffer === true
          ? generatePayload(offerTicket, order)
          : generatePayload(initialTicketState, order),
    })
      .then((res) => {
        if (res.data?.status) {
          setOrderOutOfferListData((prev: any) =>
            prev.filter((item: any) => item?.order_id !== order?.order_id)
          );
          if (status) {
            setAcceptedOrder(true);
            setOrderStatus(res.data?.order?.order_status);
            if (makeoffer) {
              const result = getDifferentKeys(
                generatePayload(offerTicket, order),
                generatePayload(initialTicketState, order)
              );
              setEditedAfterConfirmation(result);
              setOrder((prev: any) => {
                return { ...prev, ...generatePayload(offerTicket, order) };
              });
            }
          } else {
            document.getElementById("orderoutofferdetails-close")?.click();
          }
          setConfirmation(CONFIRMATION_INITIAL_STATE);
          refetchOrderOutOffer();
          scrollToTop("orderoutofferbody");
        } else {
          showAlert(res.message, true);
        }
      })
      .catch((err) => {
        console.error("Order action error:", err);
      })
      .finally(() => {
        setActionLoader(false);
      });
  };

  const navigateToSales = () => {
    navigate(SALES_REDIRECT_ROUTES[orderStatus], { replace: true });
    coreLayout.setGlobalSales(order?.order_id);
  };

  const ticketTypesToDisplay = useMemo(() => {
    let ticketTypes: Array<any> =
      order?.ticket_types?.map((item: any) =>
        ticketTypeRenameForSalesReport(item.name, item.subTypes)
      ) || [];

    ticketTypes = [ticketTypeRename(order?.ticket_type), ...ticketTypes];

    return ticketTypes;
  }, [order]);

  useEffect(() => {
    if (layout?.selectedOrderOutOffer?.status === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    setOrder(layout?.selectedOrderOutOffer?.order);
    setAcceptedOrder(false);
  }, [layout?.selectedOrderOutOffer]);

  // START VIEW DETAIL API
  const { isFetching, data: viewOrder } = useQuery(
    [
      QUERIES.DASHBOARD_OFFERED_ORDERS_DETAILS,
      layout.selectedOrderOutOffer?.order?.order_id,
    ],
    () =>
      getOfferedOrders({
        order_id: layout.selectedOrderOutOffer?.order?.order_id,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: layout.selectedOrderOutOffer?.order?.order_id ? true : false,
      onSettled(data, error) {
        const orderData = data?.data?.orders?.[0];
        const options = data?.data?.options;
        const category = options?.categories?.find(
          (item: any) => item?.name === orderData?.ticket_category
        );
        const ticketTypes = getProperTicketTypeWithSubticketCombine(
          options?.ticket_types
        );

        const newRenameTicketType = ticketTypes?.map((obj: any) => {
          return {
            ...obj,
            name: ticketTypeRename(obj?.id),
          };
        });
        let section;
        if (category) {
          setSectionsOptions(category?.sections);
          section = category?.sections?.find(
            (section: any) => section.name === orderData?.seating_location
          );
        }
        const { benefits, restrictions } = separateBenefitsAndRestrictions(
          orderData?.restrictions,
          options?.benefits,
          options?.restrictions
        );

        console.log(benefits, restrictions, "benefits, restrictions");

        console.log(newRenameTicketType, "newRenameTicketType");

        console.log(
          ticketTypeRenameForSalesReport(
            orderData?.ticket_type,
            orderData?.sub_ticket_type
          ),
          "dasfkhfsd"
        );

        console.log(
          getTicketTypeValue(
            orderData?.ticket_type,
            orderData?.sub_ticket_type,
            newRenameTicketType
          ),
          "mmmmmmmh"
        );
        const ticket_details = {
          ticket_type:
            newRenameTicketType?.find(
              (ticket_type: any) =>
                ticket_type?.id ===
                getTicketTypeValue(
                  orderData?.ticket_type,
                  orderData?.sub_ticket_type,
                  newRenameTicketType
                )?.id
            ) || {},
          row: orderData?.items?.[0]?.row,
          first_seat: orderData?.items?.[0]?.seat,
          restrictions: restrictions || [],
          benefits: benefits || [],
          ticket_price: orderData?.ticket_price,
          deliver_by_date: orderData?.deliver_by,
          sub_ticket_type: orderData?.sub_ticket_type,
          order_total:
            Number(orderData?.ticket_price) * orderData?.ticket_quantity,
          category: category,
          section: section,
          ticket_currency: orderData?.ticket_currency,
        };
        setInitialTicketState((prev) => {
          return {
            ...prev,
            ...ticket_details,
          };
        });
        setOfferTicket((prev) => {
          return {
            ...prev,
            ...ticket_details,
          };
        });

        const newOptions = {
          ...options,
          // ticket_types: options?.ticket_types?.map((obj: any) => {
          //   return {
          //     ...obj,
          //     name: ticketTypeRename(obj?.id),
          //   };
          // }),
          ticket_types: newRenameTicketType,
        };
        setOptions(newOptions);
      },
    }
  );

  const handleOnChange = (data: any) => {
    setOfferTicket((prev: any) => {
      return {
        ...prev,
        [data?.target?.name]: data?.target?.value,
        ...(data?.target?.name === "category" && { section: null }),
      };
    });
  };

  const changedTicketPrice = useMemo(() => {
    return (
      (typeof offerTicket?.ticket_price === "object"
        ? offerTicket?.ticket_price?.value
        : offerTicket?.ticket_price) !==
      (typeof initialTicketState?.ticket_price === "object"
        ? initialTicketState?.ticket_price?.value
        : initialTicketState?.ticket_price)
    );
  }, [offerTicket?.ticket_price, initialTicketState?.ticket_price]);

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    // setOurderOutOfferCustomLoading(true);

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      setOfferTicket
    );
  };

  useEffect(() => {
    if (offerTicket?.category) {
      setSectionsOptions(offerTicket.category?.sections);
    }
  }, [offerTicket.category]);

  const ticketPrice =
    typeof offerTicket?.ticket_price === "object"
      ? offerTicket?.ticket_price?.value
      : offerTicket?.ticket_price;

  const ticketPriceError = useMemo(() => {
    return ticketPrice <= 0 ? "Ticket price should be greater than zero" : "";
  }, [ticketPrice]);

  return (
    <>
      {layout?.selectedOrderOutOffer?.status === true && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/70 z-40"></div>
      )}

      <div
        data-te-modal-init
        className={`offcanvas offcanvas-end add_teammember fill_canvas  fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium ${
          coreLayout?.asideMenuOpen
            ? "md:max-w-[calc(100%_-_13.8125rem)]"
            : "md:max-w-[calc(100%_-_3.75rem)]"
        } w-full upload-ticket-popup z-40  ${
          layout?.selectedOrderOutOffer?.status === true && "show"
        }`}
        // tabIndex={-1}
        id="orderoffer"
        aria-labelledby="orderofferLabel"
      >
        <div className="md:max-w-[45.3125rem] max-w-full bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className=" flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Order offer
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                id="orderoutofferdetails-close"
                onClick={() => {
                  setConfirmation(CONFIRMATION_INITIAL_STATE);

                  layout.setSelectedOrderOutOffer({});
                  setMakeoffer(false);
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>

          <div
            className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5"
            id="orderoutofferbody"
          >
            {!acceptedOrder && (
              <div className="orderofferwork p-[.9375rem] pb-[.8125rem] bg-indigo-500 bg-opacity-[7%] rounded mb-5">
                <div className="title flex items-center text-sm15 font-semibold mb-[.9375rem]">
                  <IKTSVG
                    path={infoIcon}
                    className="mr-2 flex"
                    svgClassName="fill-indigo-500 w-[1.125rem] h-[1.125rem]"
                  />
                  <div className="leading-[1.125rem]">
                    How order offers work
                  </div>
                </div>
                <div className="listingUL numberList">
                  <ol className="">
                    <li className="leading-[1.125rem]">
                      Review the event/ticket details, and if you can match the
                      order exactly, click the ‘Accept order’ button.
                    </li>
                    <li className="leading-[1.125rem]">
                      If you can’t match the order exactly, use the ‘Make an
                      offer’ box below and state any differences in the tickets
                      you can offer.
                    </li>
                    <li className="leading-[1.125rem]">
                      Once an offer is submitted, our support team will review
                      all offers and notify you if yours is accepted.
                    </li>
                  </ol>
                </div>
              </div>
            )}

            {acceptedOrder && (
              <div className="orderofferreview p-[.9375rem] bg-green-600 bg-opacity-[7%] rounded mb-5">
                <div className="title flex items-center text-sm15 font-semibold mb-[.9375rem]">
                  <IKTSVG
                    path={TickIcon}
                    className="mr-2 flex"
                    svgClassName="fill-indigo-500 w-[1.125rem] h-[1.125rem]"
                  />
                  <div className="leading-[1.125rem]">Offer under review</div>
                </div>
                <div className="listingUL numberList text-sm12 font-medium">
                  <p>
                    Thank you for making an offer for this order. Our support
                    team will review all offers and notify you if yours is
                    accepted.
                  </p>
                </div>
              </div>
            )}

            <div className="firstRow p-[.9375rem] border mb-5 rounded">
              <div className="title mb-[.9375rem] relative pr-[1.125rem]">
                <h3 className="text-sm13 font-semibold leading-4">
                  {order?.event_description}
                </h3>
                {order?.event_expired_soon === true && (
                  <div className="absolute top-0 right-0">
                    <IKTSVG
                      path={notifyIcon}
                      svgClassName="w-[.5625rem] h-2.5"
                      className="w-[1.125rem] h-[1.125rem] bg-rose-500 flex items-center justify-center rounded-full cursor-pointer hover:bg-indigo-500"
                      dataTooltipID={`order-offer-details-${order?.order_id}`}
                    />

                    <TooltipPortal
                      id={`order-offer-details-${order?.order_id}`}
                      content={`Offer ending in ${order?.event_expired_hours}hrs`}
                      className="!bg-white !py-1 !px-1.5 font-medium !text-xxs z-[1045] mt-1.5 text-center !opacity-100"
                    />
                  </div>
                )}
              </div>
              <div className="eventInfo flex flex-wrap gap-x-2.5 gap-y-1">
                <div className="singleInfo">
                  <p className="flex items-center text-xs text-gray-500 font-medium">
                    <IKTSVG
                      path={CalenderSVG}
                      className="mr-1.5"
                      svgClassName="w-3 h-3 fill-gray-500 "
                    />
                    {moment(order?.event_date || new Date()).format(
                      "ddd, DD MMM YYYY"
                    )}
                  </p>
                </div>
                <div className="singleInfo">
                  <p className="flex items-center text-xs text-gray-500 font-medium">
                    <IKTSVG
                      path={ClockIcon}
                      className="mr-1.5"
                      svgClassName="w-3 h-3 fill-gray-500 "
                    />
                    {moment(order?.event_date || new Date()).format("HH:mm")}
                  </p>
                </div>
                <div className="singleInfo">
                  <p className="flex items-center text-xs text-gray-500 font-medium">
                    <IKTSVG
                      path={LocationIcon}
                      className="mr-1.5"
                      svgClassName="w-3 h-3 fill-gray-500 "
                    />
                    {order?.address}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstRow p-[.9375rem] border rounded rounded-b-none border-b-0">
              <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                Ticket detail
              </div>
              <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.9375rem]">
                <LableInput
                  label={"Ticket Type"}
                  value={ticketTypeRenameForSalesReport(
                    order?.ticket_type,
                    order?.sub_ticket_type
                  )}
                  halfWidth={true}
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("ticket_type")
                  }
                />
                <LableInput
                  label={"Quantity"}
                  value={order?.ticket_quantity}
                  halfWidth={true}
                />
                <LableInput
                  label={"Deliver by"}
                  value={order?.deliver_by ?? order?.deliver_by}
                  halfWidth={true}
                />
                <LableInput
                  label={"Days to event"}
                  value={order?.time_to_event}
                  halfWidth={true}
                />
                {/* <div className="tagLine w-full px-[.4375rem]">
                  <p className="bg-rose-500 bg-opacity-[7%] px-2.5 py-[.4375rem] left-4 text-sm12 font-medium rounded">
                    The accepted ticket types for this order are{" "}
                    <span className="font-semibold">
                      {ticketTypesToDisplay &&
                        ticketTypesToDisplay
                          .slice(0, ticketTypesToDisplay?.length - 1)
                          .join(", ")}
                    </span>{" "}
                    {ticketTypesToDisplay?.length >= 2 && "and"}{" "}
                    <span className="font-semibold">
                      {ticketTypesToDisplay &&
                        ticketTypesToDisplay[ticketTypesToDisplay?.length - 1]}
                    </span>{" "}
                    only. Once you accept the order, you can change the ticket
                    type from your order details page.
                  </p>
                </div> */}

                <LableInput
                  label={"Category"}
                  value={
                    options?.categories?.find(
                      (item: any) => item?.name === order?.ticket_category
                    )?.name
                  }
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("category_id")
                  }
                />

                <LableInput
                  label={"Section/block"}
                  value={
                    acceptedOrder
                      ? options?.categories
                          ?.find((item: any) => item?.id === order?.category_id)
                          ?.sections?.find(
                            (item: any) => item?.id === order?.section
                          )?.name
                      : order?.seating_location
                  }
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("section")
                  }
                />

                <LableInput
                  label={"Row"}
                  value={acceptedOrder ? order?.row : order?.items?.[0]?.row}
                  highlight={
                    acceptedOrder && editedAfterConfirmation?.includes("row")
                  }
                />

                <LableInput
                  label={"First seat"}
                  value={
                    acceptedOrder ? order?.first_seat : order?.items?.[0]?.seat
                  }
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("first_seat")
                  }
                />

                <LableInput
                  label="Ticket price"
                  value={`${SYMBOLS[order?.ticket_currency]}${decimalNumber(
                    order?.ticket_price
                  )}`}
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("ticket_price")
                  }
                />

                <LableInput
                  label={"Order total"}
                  value={`${SYMBOLS[order?.ticket_currency]}${decimalNumber(
                    Number(order?.ticket_price) * order?.ticket_quantity
                  )}`}
                  highlight={
                    acceptedOrder &&
                    editedAfterConfirmation?.includes("ticket_price")
                  }
                />
              </div>
            </div>
            <div
              className={`firstRow p-[.9375rem] border mb-5 rounded rounded-t-none ${
                acceptedOrder &&
                (editedAfterConfirmation?.includes("benefits") ||
                  editedAfterConfirmation?.includes("restrictions")) &&
                "outline-indigo-500"
              }`}
            >
              <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                Benefits & restrictions
              </div>
              <div className="benifitList text-sm flex flex-wrap">
                {order?.restrictions?.length > 0 ? (
                  order.restrictions.map((restriction: any, index: number) => (
                    <div
                      className="flex w-1/2 text-sm12 text-gray-500 leading-[.9375rem]"
                      key={index}
                    >
                      -{" "}
                      {typeof restriction === "object"
                        ? restriction?.name
                        : restriction}
                    </div>
                  ))
                ) : (
                  <div className="text-sm12 text-gray-500 leading-[.9375rem]">
                    N/A
                  </div>
                )}
              </div>
            </div>
            {!acceptedOrder && !isGodAdmin && (
              <div
                className={`firstRow border mb-0 rounded makeoffer ${
                  makeoffer && "border-indigo-500"
                }`}
              >
                <div className="text-sm13 font-semibold leading-4 flex items-center px-4 py-3">
                  <div className="flex items-center">
                    <label className="relative inline-flex items-center cursor-pointer false [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none mr-3">
                      <input
                        type="checkbox"
                        // name="addAllEvents"
                        value=""
                        className="sr-only peer"
                        checked={makeoffer}
                        onChange={() => setMakeoffer(!makeoffer)}
                      />
                      <div
                        className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                          } w-[1.875rem] h-3.5  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-[1.125rem] after:w-[1.125rem] after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 relative`}
                      ></div>
                    </label>
                    <h4 className="text-sm13 font-semibold leading-4">
                      Make an offer
                    </h4>
                  </div>
                </div>
                {makeoffer && (
                  <div className="offercontent border-t p-3.5">
                    <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.9375rem] ">
                      {/* <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem]  ${
                          initialTicketState?.ticket_type?.id !==
                            offerTicket?.ticket_type?.id && "edited"
                        } `}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Ticket type
                        </label>
                        <DropDownField
                          name="ticket_type"
                          options={options?.ticket_types}
                          value={offerTicket?.ticket_type}
                          handleOnChange={(data: any) => handleOnChange(data)}
                          placeholder={
                            offerTicket?.ticket_type ? "" : "Ticket type"
                          }
                          menuPortalTarget={true}
                          menuPosition="fixed"
                          isClearable={false}
                        />
                      </div> */}
                      <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] ${
                          initialTicketState?.category?.id !==
                            offerTicket?.category?.id && "edited"
                        }`}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Category
                        </label>
                        <DropDownField
                          name="category"
                          options={options?.categories}
                          value={offerTicket?.category}
                          handleOnChange={(data: any) => {
                            handleOnChange(data);
                          }}
                          placeholder={offerTicket?.category ? "" : "Category"}
                          menuPortalTarget={true}
                          menuPosition="fixed"
                          isCapital={true}
                          isValueCapital={true}
                          isClearable={false}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem]  ${
                          initialTicketState?.section?.id !==
                            offerTicket?.section?.id && "edited"
                        }`}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Section/block
                        </label>
                        <DropDownField
                          name="section"
                          options={sectionsOptions}
                          value={offerTicket?.section}
                          handleOnChange={(data: any) => handleOnChange(data)}
                          className={`!h-[1.875rem] bg-white`}
                          placeholder={
                            offerTicket?.section ? "" : "Section/block"
                          }
                          menuPortalTarget={true}
                          menuPosition="fixed"
                          isCapital={true}
                          isValueCapital={true}
                          minMenuHeight={300}
                          isClearable={false}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/4 w-full px-[.4375rem] `}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Row
                        </label>
                        <TextInput
                          name={`row`}
                          type="text"
                          value={offerTicket?.row}
                          required={false}
                          placeholder="None"
                          className={`!h-[1.875rem] bg-white ${
                            initialTicketState?.row !== offerTicket?.row &&
                            "border-indigo-500"
                          }`}
                          handleOnChange={(data: any) => handleOnChange(data)}
                          noHighlight={true}
                          checkHidden={true}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/4 w-full px-[.4375rem] `}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          First seat
                        </label>
                        <TextInput
                          name={`first_seat`}
                          type="text"
                          value={offerTicket?.first_seat}
                          handleOnChange={(data: any) => handleOnChange(data)}
                          required={false}
                          placeholder="None"
                          className={`!h-[1.875rem] bg-white ${
                            initialTicketState?.first_seat !==
                              offerTicket?.first_seat && "border-indigo-500"
                          }`}
                          noHighlight={true}
                          checkHidden={true}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] ${
                          !_.isEqual(
                            offerTicket?.benefits,
                            initialTicketState?.benefits
                          ) && "edited"
                        }`}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Benefits
                        </label>
                        {/* <DropDownField /> */}

                        <MultiDropDownField
                          name="benefits"
                          className=""
                          classNamePrefix={"form_multiDropDown"}
                          options={options?.benefits}
                          value={offerTicket?.benefits}
                          handleOnChange={(data: any) => handleOnChange(data)}
                          menuPortalTarget={true}
                          menuPosition="fixed"
                          minMenuHeight={300}
                          handleOnSelection={(
                            data: any,
                            visibleOptions: any
                          ) => {
                            handleOnSelection(
                              data,
                              "benefits",
                              options?.benefits,
                              visibleOptions
                            );
                          }}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] ${
                          !_.isEqual(
                            offerTicket?.restrictions,
                            initialTicketState?.restrictions
                          ) && "edited"
                        }`}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Restrictions
                        </label>
                        <div className={`panelty-note w-full  `}>
                          <MultiDropDownField
                            name="restrictions"
                            classNamePrefix={"form_multiDropDown"}
                            // placeholder="Restriction"
                            value={offerTicket?.restrictions}
                            handleOnChange={(data: any) => handleOnChange(data)}
                            options={options?.restrictions}
                            isMultiSelection={true}
                            menuPortalTarget={true}
                            menuPosition="fixed"
                            minMenuHeight={300}
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) => {
                              handleOnSelection(
                                data,
                                "restrictions",
                                options?.restrictions,
                                visibleOptions
                              );
                            }}
                          />
                        </div>

                        {/* <DropDownField /> */}
                      </div>
                      <div
                        className={`${
                          ticketPriceError ? "" : "singleFOrmBLock"
                        }   md:w-1/2 w-full px-[.4375rem] ${
                          changedTicketPrice && "edited"
                        } proceed-price addinv_price`}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Ticket price
                        </label>
                        <PriceField
                          value={
                            typeof offerTicket?.ticket_price === "object"
                              ? offerTicket?.ticket_price?.value
                              : decimalNumber(offerTicket?.ticket_price)
                          }
                          currenncySymbol={order?.ticket_currency}
                          name="ticket_price"
                          handleOnChange={(data: any) => handleOnChange(data)}
                          className={`${
                            changedTicketPrice && "border border-indigo-500"
                          }`}
                          handleOnBlur={(data: any) => {
                            const newData = {
                              ...data,
                              ...data,
                              target: {
                                ...data.target,
                                value: {
                                  ...data.target.value,
                                  value: isValidPriceFieldNumber(
                                    data.target.value.value
                                  )
                                    ? decimalNumber(data.target.value.value)
                                    : decimalNumber(0),
                                },
                              },
                            };
                            handleOnChange(newData);
                          }}
                          errorClass={`${
                            ticketPriceError ? ticketPriceError : ""
                          }`}
                          errorMessage={`${
                            ticketPriceError ? ticketPriceError : ""
                          }`}
                          errorEventIndex={ticketPriceError && `error-1`}
                        />
                      </div>
                      <div
                        className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] `}
                      >
                        <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                          Order total
                        </label>
                        <PriceField
                          isDisabled={true}
                          value={decimalNumber(orderTotal)}
                          currenncySymbol={order?.ticket_currency}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {!acceptedOrder && !isGodAdmin && (
            <div className="  bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-1.5 bg-white z-10 border-t min-h-[3.125rem] offermodelfooter">
              <div className="r_btn gap-2.5 flex md:flex-wrap items-center md:justify-end w-full max-md:overflow-auto max-md:whitespace-nowrap">
                {!confirmation?.status && !acceptedOrder && (
                  <>
                    <button
                      type="button"
                      className="border border-gray-300 hover:border-indigo-500  hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-[.5625rem] py-[.1875rem] leading-[1.0625rem]"
                      onClick={() => {
                        setConfirmation(CONFIRMATION_INITIAL_STATE);

                        layout.setSelectedOrderOutOffer({});
                        setMakeoffer(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`${
                        ticketPriceError && "!pointer-events-none !bg-gray-500"
                      }  relative z-10 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 transition font-medium rounded text-sm13 px-2.5 py-1 leading-[1.0625rem] 
                    
                        `}
                      onClick={() => {
                        // setConfirmationActive(true);
                        setConfirmation({
                          status: true,
                          message:
                            "Confirm this order can be fulfilled if offer accepted?",
                          output: "accept",
                        });
                      }}
                      // disabled={isAcceptDisabled}
                    >
                      {makeoffer ? "Make an offer" : "Accept order"}
                    </button>
                  </>
                )}

                {confirmation?.status && (
                  <div className="confirm_btn flex md:flex-wrap max-md:overflow-auto gap-2.5 items-center md:justify-end w-full ">
                    <p className="text-sm13 font-medium">
                      {confirmation?.message}
                    </p>
                    <button
                      className="relative text-sm13 font-medium leading-4 px-[.5625rem] pt-[.1875rem] pb-[.1875rem] border border-gray-300 rounded hover:border-indigo-500 hover:bg-indigo-500 hover:text-white w-[3.9375rem] h-6 flex items-center justify-center"
                      onClick={() => {
                        // setConfirmationActive(false);
                        setConfirmation(CONFIRMATION_INITIAL_STATE);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={` ${
                        ticketPriceError && "!pointer-events-none !bg-gray-500"
                      } relative active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 transition font-medium rounded text-sm13 px-2.5 pt-[0.2188rem] pb-[0.2813rem] leading-4 disabled:pointer-events-none disabled:bg-gray-500`}
                      onClick={() => {
                        if (confirmation?.output === "accept") {
                          handleOrderAction({
                            status: true,
                            user_id: currentUserId,
                          });
                        }

                        if (confirmation?.output === "reject") {
                          handleOrderAction({
                            status: false,
                            user_id: currentUserId,
                          });
                        }
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {actionLoader && <ProgressBardLoader secondCounter={2} />}
    </>
  );
};

export default OrderOutOfferDetailsModal;
