const ReportCardSub = ({
  title,
  reportsCardData,
  buttonText = "",
  handleOnButtonClick,
}: any) => {
  return (
    <div className="outline outline-1 outline-gray-200 bg-white rounded max-sm:w-auto max-sm:flex-[0_0_auto] max-sm:min-w-[50%] sm:w-1/2 px-2.5 pt-[.4375rem] pb-[.5625rem]">
      <div className="flex flex-wrap sm:flex-nowrap justify-between items-center">
        <h3 className="md:text-lg md:leading-6">{reportsCardData}</h3>
        {buttonText && reportsCardData !== 0 && (
          <button
            type="button"
            className={`${
              buttonText === "Details required"
                ? "bg-rose-500"
                : "bg-violet-500"
            }  text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500 ml-3`}
            onClick={handleOnButtonClick}
          >
            {buttonText}
          </button>
        )}
      </div>
      <p className="text-xs font-medium text-gray-500 pt-1">{title}</p>
    </div>
  );
};

export default ReportCardSub;
