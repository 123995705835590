import { EyeIcon } from "assets/media/icons/other_icons/EyeIcon";
import { SettingIcon } from "assets/media/icons/other_icons/SettingIcon";
import { TransferIcon } from "assets/media/icons/other_icons/TransferIcon";
import { useState } from "react";
import CardPlus from "../../../assets/media/icons/other_icons/card_puls.svg";
import masterCards from "../../../assets/media/icons/other_icons/masterCards.svg";
import visalogo from "../../../assets/media/icons/other_icons/visaLogo.svg";
import logo from "../../../assets/media/icons/tix_logo.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { colorVirtualCard } from "../../../helpers/AssetHelpers";
import { decimalNumber } from "../../../helpers/Functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { isCardPending } from "../core/_functions";

const TxPayVirtualCard = ({
  cardData,
  onClick,
  virtualCardLoader,
  isCardLimitOver,
  isBalanceLoading,
  handleScrollToTop,
}: any) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  return (
    <>
      {cardData?.length > 0 ? (
        <>
          {/* FULL VIRTUAL CARD */}
          {cardData
            ?.sort((a: any, b: any) =>
              a.status === "Inactive" ? 1 : b.status === "Inactive" ? -1 : 0
            )
            .map((obj: any, index: any) => {
              return (
                <div
                  className={`single-virtual-card px-2.5 mb-5 cursor-pointer ${
                    isCardPending(obj) && "pointer-events-none cursor-default"
                  } `}
                >
                  <div
                    className={`inner_card min-w-[21.0625rem] border rounded-xl !bg-white hover:!border-indigo-500  ${
                      obj?.active && "!border-indigo-500 bg-opacity-[7%]"
                    } ${obj?.status === "Inactive" && " !border-gray-100"} ${
                      obj?.loader && "shimmer-effect"
                    } `}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (isCardPending(obj)) return;
                      onClick("view-transactions-click", obj);
                    }}
                    id={isCardPending(obj) ? "" : "view-click"}
                  >
                    <div
                      className={`card_detail_top min-h-[13.25rem] bg-vir_card_bg bg-no-repeat bg-left-bottom relative z-0 rounded-xl ${
                        obj?.status === "Inactive" ? "m-0" : "-m-[.0625rem]"
                      }
                      ${obj?.status === "Inactive" && "!bg-gray-300"}`}
                      style={{
                        backgroundColor: colorVirtualCard(
                          typeof obj?.color === "object"
                            ? obj?.color?.name
                            : obj?.color
                        ),
                      }}
                    >
                      <span className="bg-gradient-to-br from-white/0 to-white/0 via-white absolute left-0 top-0 w-full h-full opacity-[7%] -z-[1]"></span>
                      <div className="card-detail p-5 absolute top-0 left-0 w-full h-full flex justify-between flex-col">
                        <div className="top-card flex items-center justify-between">
                          <div className="logo card-svg-logo">
                            <IKTSVG
                              path={logo}
                              svgClassName="w-[9.9375rem] h-6 fill-white"
                            />
                          </div>
                          <span className="text-sm11 font-medium text-white ellips-text max-w-[8.125rem]">
                            {obj?.cardName}
                          </span>
                        </div>
                        <div className="bottom-card flex items-end justify-between">
                          <span className="text-sm15 text-white">
                            •••• •••• •••• {obj?.cardNumber}
                          </span>
                          <div className="logo">
                            <IKTSVG
                              path={
                                obj?.cardBrand === "MasterCard"
                                  ? masterCards
                                  : visalogo
                              }
                              svgClassName={`w-[3.875rem] h-auto max-h-[2.25rem]`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_details flex items-center justify-between px-5 py-3">
                      {isCardPending(obj) ? (
                        <div className="balance py-1">
                          <p
                            className={`text-sm12 text-gray-500 leading-4 mb-1.5  ${
                              obj?.status === "Inactive" && "!text-gray-500"
                            }`}
                          >
                            Balance
                          </p>
                          <p
                            className={`text-lg text-gray-400 font-medium leading-4 mb-0`}
                          >
                            Pending
                          </p>
                        </div>
                      ) : (
                        <div className="balance">
                          <p
                            className={`text-sm12 text-gray-500 leading-4 mb-1.5 ${
                              obj?.status === "Inactive" && "!text-gray-500"
                            }`}
                          >
                            Balance
                          </p>
                          {isBalanceLoading ? (
                            <div className="shimmer-effect rounded-lg w-20 text-sm12 font-medium text-gray-500 flex-1 mt-1.5 block h-6">
                              <span className="inline-block text-xs">0000</span>
                            </div>
                          ) : (
                            <h3
                              className={`text-[1.3125rem] leading-6  ${
                                obj?.status === "Inactive" && "!text-gray-500"
                              }`}
                            >
                              {obj?.currency}
                              {obj?.totalBalance
                                ? decimalNumber(obj?.totalBalance)
                                : ""}
                            </h3>
                          )}
                        </div>
                      )}
                      <div
                        className={`buttons p-3 flex items-center justify-between gap-x-3.5 rounded bg-gray-100 min-h-[2.5rem]`}
                        onMouseEnter={() => setIsButtonClicked(false)}
                      >
                        <button
                          className={`group ${
                            obj?.status === "Inactive" && "pointer-events-none"
                          }`}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();

                            setIsButtonClicked(true);

                            onClick("transfer", obj);

                            setTimeout(() => {
                              onClick("view-transactions-click", obj);
                            }, 100);
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target={
                            isCardPending(obj) ? "" : "#offcanvasTransfer"
                          }
                          aria-controls="offcanvasTransfer"
                          data-tooltip-id={`transfer-${index}`}
                        >
                          {!isButtonClicked && (
                            <TooltipPortal
                              id={`transfer-${index}`}
                              content={"Transfer"}
                              place="top"
                              variant="light"
                              className="text-center z-[1000] !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs  max-w-[9rem] cursor-default"
                            />
                          )}

                          <span
                            className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 rotate-90 ${
                              isCardPending(obj) && "!fill-gray-400"
                            } ${
                              obj?.status === "Inactive" && "!fill-gray-400"
                            }`}
                          >
                            <TransferIcon className="w-[.9375rem] h-[.9375rem]" />
                          </span>
                        </button>
                        <button
                          className={`group  `}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();

                            setIsButtonClicked(true);

                            onClick("setting", obj);
                            setTimeout(() => {
                              onClick("view-transactions-click", obj);
                            }, 100);
                            const settingPopup =
                              document.getElementById("settingPopup");
                            if (settingPopup) {
                              settingPopup.scrollTo({
                                top: 0,
                              });
                            }
                          }}
                          data-bs-toggle="offcanvas"
                          data-bs-target={
                            isCardPending(obj) ? "" : "#offcanvasView"
                          }
                          aria-controls="offcanvasView"
                          data-tooltip-id={`setting-${index}`}
                        >
                          <span
                            className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 ${
                              isCardPending(obj) && "!fill-gray-400"
                            } `}
                          >
                            <SettingIcon className="w-4 h-[.8125rem]" />
                          </span>
                          {!isButtonClicked && (
                            <TooltipPortal
                              id={`setting-${index}`}
                              place="top"
                              variant="light"
                              content={"Settings"}
                              className="text-center z-[1000] !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs max-w-[9rem] cursor-default"
                            />
                          )}
                        </button>
                        <button
                          data-tooltip-id={`View-${index}`}
                          className="group"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (isCardPending(obj)) return;

                            onClick("view-transactions-click", obj);
                          }}
                        >
                          <TooltipPortal
                            id={`View-${index}`}
                            content={"View"}
                            className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs  max-w-[9rem] cursor-default z-[9999]"
                          />

                          <span className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500">
                            <EyeIcon
                              className={`w-4 h-4  ${
                                isCardPending(obj) && "fill-gray-400"
                              } `}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      ) : null}

      {/* EMPTY ADD CARD */}
      <div className={`single-virtual-card px-2.5 mb-5  `}>
        <div
          className={`group inner_card min-w-[21.0625rem] border rounded-xl transition-all ${
            virtualCardLoader && "shimmer-effect"
          } ${
            !isCardLimitOver ? "hover:border-indigo-500" : "pointer-events-none"
          }`}
        >
          <div className="card_detail_top bg-gray-300 transition-all group-hover:bg-violet-400 min-h-[13.25rem] bg-vir_card_bg1 bg-[13.625rem,13.25rem] bg-no-repeat bg-left-bottom relative z-0 rounded-xl -m-[.0625rem]">
            <span className="bg-gradient-to-br from-white/0 to-white/0 via-white absolute left-0 top-0 w-full h-full opacity-[7%] -z-[1]"></span>
            <div className="card-detail p-5 absolute top-0 left-0 w-full h-full flex justify-between flex-col">
              <div className="top-card flex items-center justify-between">
                <div className="logo card-svg-logo">
                  <IKTSVG
                    path={logo}
                    svgClassName="w-[9.9375rem] h-6 fill-white"
                  />
                </div>
                <span className="text-sm11 font-medium text-white ellips-text max-w-[8.125rem]">
                  Add card
                </span>
              </div>

              <div className="bottom-card flex items-center justify-between">
                <span className="text-sm15 text-white"></span>
                <div className="logo">
                  <IKTSVG path={visalogo} svgClassName="w-[3.875rem] h-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_details w-full inline-flex items-center justify-between px-5 py-[.9375rem]">
            <div
              className={`group flex items-center cursor-pointer ${
                isCardLimitOver && "pointer-events-none"
              }`}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasAddcard"
              aria-controls="offcanvasAddcard"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onClick("add-card", "");
                handleScrollToTop();
              }}
            >
              <div className="buttons p-[.8125rem] bg-gray-100 flex items-center justify-between gap-x-3.5 rounded  min-h-[2.5rem] transition-all">
                <button
                  className="disabled:pointer-events-none"
                  disabled={isCardLimitOver}
                >
                  <IKTSVG
                    path={CardPlus}
                    className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 stroke-violet-500 group-hover:stroke-indigo-500 rotate-90 transition-all ${
                      isCardLimitOver && "!fill-gray-400 !stroke-gray-400"
                    }`}
                    svgClassName={` fill-violet-500 group-hover:fill-indigo-500 ${
                      isCardLimitOver && "!fill-gray-400 "
                    }`}
                  />
                </button>
              </div>
              <p
                className={
                  "pl-[.625rem] text-sm13 group-hover:text-indigo-500 transition-all"
                }
              >
                Add card
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TxPayVirtualCard;
