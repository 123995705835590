import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import _ from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import DropDownField from "../../../../components/formComponent/DropDownField";
import PriceField from "../../../../components/formComponent/PriceField";
import Textfield from "../../../../components/formComponent/Textfield";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  convertToFormattedData,
  decimalNumber,
  getTicketTypeValue,
  handleSelectDeselectAll,
  separateBenefitsAndRestrictions,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  ORDER_ASSIGN_TO_NEW_TEAM,
  ORDER_ON_OFFER,
  QUERIES,
  SYMBOLS,
} from "../../../../helpers/const";
import { getEventDetails } from "../../../add_inventory/core/_requsets";
import { SalesContext } from "../../core/SalesProvider";
import {
  getOrderReplacementTeamListingPrice,
  offerOrder,
} from "../../core/_requests";
import BackToOrder from "./components/BackToOrder";
import EventDetails from "./components/EventDetails";
import Header from "./components/Header";
import HighligtedFilter from "./components/HighligtedFilter";
import Label from "./components/Label";
import SellarPenalityDetails from "./components/SellarPenalityDetails";

interface stateType {
  seller_level: any;
  team_id: any;
  ticketDetails: {
    category: any;
    section: any;
    row: any;
    first_seat: any;
    ticketPrice: any;
    ticket_type: any;
  };
  penalty: {
    penaltyType: any;
    penaltyValue: any;
    total: any;
    notes: any;
  };
  orderAdmistration: {
    orderStatus: any;
    internalNote: any;
  };
  deduct_from_wallet: any;
  benefits: any;
  restrictions: any;
  delivered_by: any;
  available_amount: any;
}

const INITIAL_STATE = {
  seller_level: null,
  team_id: null,
  ticketDetails: {
    category: null,
    section: null,
    row: "",
    first_seat: "",
    ticketPrice: "",
    ticket_type: "",
  },
  penalty: {
    penaltyType: "absolute",
    penaltyValue: "",
    total: 0,
    notes: "",
  },
  orderAdmistration: {
    orderStatus: null,
    internalNote: "",
  },
  deduct_from_wallet: false,
  benefits: [],
  restrictions: [],
  delivered_by: new Date(),
  available_amount: undefined,
};

const FilterBlock = ({
  label,
  content,
  width = "",
  rounded = "",
  spanClassName = "px-2.5 ",
  border = false,
  glow = false,
}: any) => {
  return (
    <div
      className={`111 single_cat max-w-[${width}] w-full flex flex-col flex-wrap items-start pr-2.5 whitespace-nowrap`}
      title={content}
    >
      <label className="text-gray-500 leading-4 mb-1">{label}</label>
      <span
        className={`w-[${width}] ellips-text ${
          content && (border || glow) ? "px-2.5 py-1" : "px-0"
        } ${rounded} ${content && border ? "border bg-white" : ""} ${
          content && glow ? " text-indigo-500 border-indigo-500" : ""
        } leading-[.9375rem]`}
      >
        {content}
      </span>
    </div>
  );
};

const OrderOnOffer = () => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const orderOnOffer = layout?.orderOnOffer?.data;
  const teamsRef = useRef<any>(null);
  const [orderPenaltyCheckbox, setOrderPenaltyCheckbox] = useState(false);

  const [eventData, setEventData] = useState<any>(null);
  const [customNewOrderLoader, setCustomNewOrderLoader] = useState(false);

  const [initialstate, setInitialstate] = useState<stateType>(INITIAL_STATE);
  const [teamsOptions, setTeamsOptions] = useState(
    coreLayout?.allowedAccess?.teams
  );

  useEffect(() => {
    if (coreLayout?.allowedAccess?.teams) {
      setTeamsOptions(coreLayout?.allowedAccess?.teams);
    }
  }, [coreLayout?.allowedAccess?.teams]);

  // Initial state
  const [state, setState] = useState<stateType>(INITIAL_STATE);

  // Filtered team options based on seller level and existing team ID
  const teamIDOptions = useMemo(() => {
    const sellerlevelIDs = state?.seller_level?.map((item: any) => item?.id);
    return layout?.teamsOptions?.filter(
      (option: any) =>
        option?.id !== orderOnOffer?.team_id &&
        sellerlevelIDs?.includes(option?.seller_level_id)
    );
  }, [state.seller_level, layout.teamsOptions, orderOnOffer?.team_id]);

  const classifiedOptions = useMemo(() => {
    const sellerlevelIDs = state?.seller_level?.map((item: any) => item?.id);

    // Filter teams based on conditions
    const filteredTeams = teamsOptions?.filter(
      (option: any) =>
        (option.is_default_team === 1 &&
          option?.id !== orderOnOffer?.team_id) ||
        (option?.id !== orderOnOffer?.team_id &&
          sellerlevelIDs?.includes(option?.seller_level_id))
    );

    // Classify teams into Default Teams and Seller Level groups
    const classified = filteredTeams?.reduce((acc: any, team: any) => {
      if (team.is_default_team === 1) {
        if (!acc["Default Teams"]) {
          acc["Default Teams"] = [];
        }
        acc["Default Teams"].push(team);
      } else {
        // const sellerLevelId = `Seller Level ${team.seller_level_id}`;
        const sellerLevel = coreLayout?.allowedAccess?.seller_levels?.find(
          (level: any) => level?.id === team.seller_level_id
        );
        if (sellerLevel) {
          if (!acc[sellerLevel?.title]) {
            acc[sellerLevel?.title] = [];
          }
          acc[sellerLevel?.title].push(team);
        }
      }
      return acc;
    }, {});

    // Ensure Default Teams appear first
    const entries = Object.entries(classified);
    entries.sort(([keyA], [keyB]) => {
      if (keyA === "Default Teams") return -1;
      if (keyB === "Default Teams") return 1;
      return 0; // Preserve other groups' order
    });

    // Convert classified object into an array with label and options
    return entries.map(([key, options]) => ({
      label: key,
      options,
    }));
  }, [state.seller_level, layout.teamsOptions, orderOnOffer?.team_id]);

  // Determine if button is disabled
  const isDisabled = useMemo(
    () => layout?.orderOnOffer?.confirmed,
    [layout?.orderOnOffer?.confirmed]
  );

  // Determine form field and review statuses
  const isActive = useMemo(
    () => ({
      fieldStatus: Boolean(state?.seller_level?.length),
      reviewStatus: Boolean(
        state?.seller_level &&
          _.size(state?.team_id) > 0 &&
          (eventData?.categories?.length === 0 ||
            state?.ticketDetails?.category) &&
          state?.ticketDetails?.ticketPrice?.value &&
          (orderPenaltyCheckbox
            ? state?.penalty?.penaltyValue &&
              state?.penalty?.total &&
              state?.penalty?.notes
            : true) &&
          state?.orderAdmistration?.orderStatus &&
          state?.orderAdmistration?.internalNote
      ),
    }),
    [
      state?.seller_level,
      state?.team_id,
      eventData?.categories,
      state?.ticketDetails?.category,
      state?.ticketDetails?.ticketPrice?.value,
      state?.penalty?.penaltyValue,
      state?.penalty?.total,
      state?.penalty?.notes,
      state?.orderAdmistration?.orderStatus,
      state?.orderAdmistration?.internalNote,
      orderPenaltyCheckbox,
    ]
  );

  // Fetch category options
  const { data: categoryData } = useQuery(
    [QUERIES.GET_CATEGORY_LISTING],
    () => getEventDetails(orderOnOffer?.event_id, ""),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSettled: (data) => {
        if (data?.status === 200) {
          const options = data?.data?.data?.[0];
          setEventData(options);

          const selectedCategory = options?.categories?.find(
            (item: any) => item?.name === orderOnOffer?.category
          );

          const { benefits, restrictions } = separateBenefitsAndRestrictions(
            orderOnOffer?.restrictions_by_key,
            layout?.salesOptions?.benefits,
            layout?.salesOptions?.restrictions
          );

          // console.log(
          //   restrictions,
          //   layout?.salesOptions?.restrictions,
          //   "benefits, restrictions"
          // );
          // console.log(state?.restrictions, "staet");

          const newStateValue = {
            ticketDetails: {
              category: selectedCategory,
              section: selectedCategory?.sections?.find(
                (item: any) => item?.name === orderOnOffer?.seating_location
              ),
              row: orderOnOffer?.row,
              first_seat: orderOnOffer?.first_seat,
              ticketPrice: {
                name: "ticketPrice",
                value: orderOnOffer?.ticket_price,
                symbol: orderOnOffer?.ticket_currency,
              },
              ticket_type: layout?.salesOptions?.ticket_type?.find(
                (item: any) =>
                  item?.id ==
                  getTicketTypeValue(
                    orderOnOffer?.ticket_type,
                    orderOnOffer?.sub_ticket_type,
                    layout?.salesOptions?.ticket_type
                  )?.id
              ),
            },
            benefits: benefits,
            restrictions: restrictions,
            delivered_by: moment(
              orderOnOffer?.deliver_by,
              "DD/MM/YYYY"
            ).toDate(),
          };

          setState((current: any) => ({
            ...current,
            ...newStateValue,
          }));

          setInitialstate((current: any) => {
            return {
              ...current,
              ...newStateValue,
            };
          });
        }
      },
    }
  );

  const transactionValue = useMemo(
    () =>
      (
        (orderOnOffer?.ticket_quantity || 0) *
        (state?.ticketDetails?.ticketPrice?.value || 0)
      ).toFixed(2),
    [orderOnOffer?.ticket_quantity, state?.ticketDetails?.ticketPrice?.value]
  );

  // Replacement data and payload
  const replacementData = useMemo(
    () => ({
      category: state?.ticketDetails?.category?.name,
      seating_location: state?.ticketDetails?.section?.name,
      first_seat: state?.ticketDetails?.first_seat,
      row: state?.ticketDetails?.row,
      ticket_currency: state?.ticketDetails?.ticketPrice?.symbol,
      ticket_price: state?.ticketDetails?.ticketPrice?.value,
      ticket_quantity: orderOnOffer?.ticket_quantity,
      ticket_type: orderOnOffer?.ticket_type,
      sub_ticket_type: orderOnOffer?.sub_ticket_type,
      transaction_value: transactionValue,
      broker_name: `${state?.team_id && state?.team_id?.length} selected`,
      seller_levels: state?.seller_level,
      restrictions: [...state?.restrictions, ...state?.benefits]?.map(
        (item: any) => {
          return { name: item?.name, id: item?.id };
        }
      ),
    }),
    [
      state?.ticketDetails,
      transactionValue,
      state?.team_id,
      orderOnOffer,
      state?.restrictions,
      state?.benefits,
    ]
  );

  const generatePayload = () => {
    const ticketTypeId = state.ticketDetails?.ticket_type?.id;
    const ticket_type =
      ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
        ? "eticket"
        : ticketTypeId;

    const ticketType = ticketTypeId
      ? ticket_type
      : state.ticketDetails?.ticket_type;
    return {
      ...(orderPenaltyCheckbox === true && {
        fine_type:
          state?.penalty?.penaltyType === "absolute"
            ? "fixed_amount"
            : "percentage",
        fine_amount: state?.penalty?.total,
        note_penalty: state?.penalty?.notes,
      }),
      order_id: orderOnOffer?.order_id,
      ticket_price: state?.ticketDetails?.ticketPrice?.value,
      order_status: state?.orderAdmistration?.orderStatus?.id,
      note_internal: state?.orderAdmistration?.internalNote,
      restrictions: [...state?.restrictions, ...state?.benefits]?.map(
        (item: any) => {
          return { name: item?.name, id: item?.id };
        }
      ),
      row: state?.ticketDetails?.row,
      first_seat: state?.ticketDetails?.first_seat,
      ticket_section: state?.ticketDetails?.section?.name,
      team_ids: state?.team_id?.map((item: any) => item?.id),
      // assign_to: "team",
      wallet_deduction: state?.deduct_from_wallet,
      delivered_by: state?.delivered_by,
      ticket_category: state?.ticketDetails?.category?.name,

      ticket_type: ticketType,
      ...(ticketType === "eticket" && {
        sub_ticket_type: ticketTypeId
          ? ticketTypeId
          : orderOnOffer?.sub_ticket_type,
      }),
    };
  };

  // const payload = useMemo(
  //   () => ({
  //     ...(orderPenaltyCheckbox === true && {
  //       fine_type:
  //         state?.penalty?.penaltyType === "absolute"
  //           ? "fixed_amount"
  //           : "percentage",
  //       fine_amount: state?.penalty?.total,
  //       note_penalty: state?.penalty?.notes,
  //     }),
  //     order_id: orderOnOffer?.order_id,
  //     ticket_price: replacementData?.ticket_price,
  //     order_status: state?.orderAdmistration?.orderStatus?.id,
  //     note_internal: state?.orderAdmistration?.internalNote,
  //     restrictions: [...state?.restrictions, ...state?.benefits]?.map(
  //       (item: any) => {
  //         return { name: item?.name, id: item?.id };
  //       }
  //     ),
  //     row: replacementData?.row,
  //     first_seat: replacementData?.first_seat,
  //     ticket_section: replacementData?.seating_location,
  //     team_ids: state?.team_id?.map((item: any) => item?.id),
  //     // assign_to: "team",
  //     wallet_deduction: state?.deduct_from_wallet,
  //     delivered_by: state?.delivered_by,
  //     ticket_category: state?.ticketDetails?.category?.name,
  //   }),
  //   [state, replacementData, orderOnOffer]
  // );
  // Replacement API call
  const { refetch: refetchOfferOrder } = useQuery(
    [QUERIES.REPLACEMENT_LIST_TEAM],
    () => offerOrder(generatePayload()),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      onSettled: (data) => {
        setCustomNewOrderLoader(false);

        if (data?.data?.status) {
          showAlert(data?.message, false, 4000);

          setTimeout(() => {
            layout.setOrderOnOffer(ORDER_ON_OFFER);
            layout.setTabValue("cancelled");
            layout?.setQuery(orderOnOffer?.order_id);
          }, 2000);
        } else if (data) {
          const errorMessage =
            data?.data?.errors || data?.errors?.[0] || "Something went wrong.";
          showAlert(errorMessage, true);
          layout.setOrderOnOffer((current: any) => ({
            ...current,
            disabledButton: false,
          }));
        }
      },
    }
  );

  const handleTeamChange = useCallback((data: any) => {
    setState((current: any) => ({
      ...current,
      [data?.target?.name]: data?.target?.value,
    }));
  }, []);

  const handleSellerLevelChange = useCallback(
    (data: any) => {
      const sellerlevelIDs = data?.target?.value?.map((item: any) => item?.id);

      setState((current: any) => ({
        ...current,
        [data?.target?.name]: data?.target?.value,
        team_id: teamsOptions?.filter(
          (option: any) =>
            // (option.is_default_team === 1 &&
            //   option?.id !== orderOnOffer?.team_id) ||
            option?.id !== orderOnOffer?.team_id &&
            sellerlevelIDs?.includes(option?.seller_level_id)
        ),
      }));
    },
    [layout?.teamsOptions, orderOnOffer?.team_id]
  );

  // Listing API call for team listing price
  const { isLoading: teamListingPriceLoading } = useQuery(
    [QUERIES.ORDER_ON_OFFER, state?.team_id],
    () =>
      getOrderReplacementTeamListingPrice({
        order_id: orderOnOffer?.order_id,
        team_id: orderOnOffer?.team_id,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(state?.team_id?.length > 0),
      onSettled: (data) => {
        if (data?.data?.status) {
          setState((current: any) => ({
            ...current,
            available_amount: data?.data?.wallet_balance,
            ticketDetails: {
              ...current?.ticketDetails,
              ticketPrice: {
                ...current?.ticketDetails?.ticketPrice,
                // value: data?.data?.target_team?.ticket_price,
                value: data?.data?.order_offer_team?.ticket_price,
                symbol: data?.data?.order_offer_team?.ticket_currency,
              },
            },
          }));
        }
      },
    }
  );

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamsRef && teamsRef?.current) {
      teamsRef.current.blur();
    }
    // setOurderOutOfferCustomLoading(true);

    handleSelectDeselectAll(data, fieldName, options, visibleOptions, setState);
  };

  return (
    <React.Fragment>
      <div
        className={` ${
          coreLayout?.asideMenuOpen
            ? "md:max-w-[calc(100%_-_13.8125rem)]"
            : "md:max-w-[calc(100%_-_3.75rem)]"
        } show  !h-full  outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition duration-300 ease-in-out font-medium w-full z-[50]  max-h-[calc(100%-3.75rem)] min-h-[calc(100%-3.75rem)] bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap pt-10 min-h-full bg-white flex flex-col">
            {/* BACK TO ORDER LISTING   */}

            <BackToOrder
              assign="offer"
              confirmed={isDisabled}
              title={`Order out to offer: ${orderOnOffer?.order_id}`}
            />
            <div className="inner-main-content p-5 overflow-auto h-full 123 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="top-filterbar rounded border mb-5">
                {/* EVENT DETAILS */}
                <EventDetails data={orderOnOffer} />
                {/* FILTER HIGHLIGHTED  */}
                <HighligtedFilter
                  data={orderOnOffer}
                  NoBorderBottom={true}
                  confirmedScreen={true}
                />
              </div>

              {/* ASSIGN NEW TEAM */}
              <div className="replacement-listing rounded border mb-5">
                <Header
                  title={`Order out to offer`}
                  className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                />

                {/* DROPDOWN FIELDS  */}
                {!isDisabled ? (
                  <div className="panelty-status md:p-5 px-2.5 py-[.9375rem] md:pt-[.9375rem] flex flex-wrap md:gap-y-5 gap-y-[.9375rem] -mx-[.4375rem]">
                    <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem]">
                      <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                        Seller Level
                      </label>
                      <MultiDropDownField
                        name="seller_level"
                        classNamePrefix={"form_multiDropDown"}
                        // placeholder="Seller Level"
                        value={state?.seller_level}
                        handleOnChange={(data: any) =>
                          handleSellerLevelChange(data)
                        }
                        options={layout?.sellerLevelOptions}
                        isMultiSelection={true}
                        noCapitalize={true}
                      />
                    </div>
                    {isActive?.fieldStatus && (
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem]">
                        <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                          Teams
                        </label>
                        <MultiDropDownField
                          name="team_id"
                          classNamePrefix={"form_multiDropDown"}
                          // placeholder="Team"
                          value={state?.team_id}
                          handleOnChange={(data: any) => handleTeamChange(data)}
                          options={classifiedOptions}
                          isMultiSelection={true}
                          selectedRef={teamsRef}
                          // handleOnSelection={(
                          //   data: any,
                          //   visibleOptions: any
                          // ) => {

                          // }}

                          handleOnSelection={(
                            data: any,
                            visibleOptions: any
                          ) => {
                            handleOnSelection(
                              data,
                              "team_id",
                              classifiedOptions,
                              visibleOptions
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`cat_detail md:px-5 px-2.5 py-[.9375rem] md:pb-5 text-sm12 flex flex-wrap bg-opacity-50 gap-x-3.5 gap-y-[.9375rem]`}
                  >
                    <FilterBlock
                      label={"Seller levels"}
                      width="9.9375rem"
                      rounded="rounded-xl"
                      // content={`${state?.seller_level?.[0]?.name}`}
                      content={`${state?.seller_level
                        ?.map((item: any) => item?.name)
                        ?.join(", ")}`}
                    />
                    <FilterBlock
                      label={"Teams"}
                      width="9.875rem"
                      rounded="rounded-xl"
                      content={`${state?.team_id?.length} selected`}
                      // content={orderAssign?.broker_name}
                    />
                  </div>
                )}
              </div>

              {isActive?.fieldStatus && (
                <>
                  {/* EDITABLE - BLOCK */}

                  <div className="order-administration rounded border mb-5 anim-fade z-[11] relative">
                    <Header
                      title={`Amend ticket details`}
                      className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />
                    {!isDisabled ? (
                      <div className="panelty-status md:p-5 px-2.5 py-[.9375rem] md:pt-[.9375rem] flex flex-wrap md:gap-y-5 gap-y-[.9375rem] -mx-[.4375rem] ">
                        <div className="panelty-note lg2:w-1/4 md:w-1/2 w-full  flex flex-wrap md:gap-y-5 gap-y-[.9375rem]">
                          {/* Ticket Type  */}
                          {/* <div
                            className={`panelty-input fullWidthDropdown md:w-1/2 w-full px-[.4375rem]   ${
                              initialstate?.ticketDetails?.ticket_type !==
                                state?.ticketDetails?.ticket_type && "edited"
                            }`}
                          >
                            <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                              Ticket type
                            </label>
                            <DropDownField
                              name="ticket_type"
                              placeholder=""
                              id={`ticketPriceorderoutofferdsa`}
                              options={layout?.salesOptions.ticket_type}
                              value={state?.ticketDetails?.ticket_type}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div> */}

                          {/* TICKET PRICE */}
                          <div
                            className={`panelty-input md:w-1/2 w-full px-[.4375rem]  ${
                              Number(
                                typeof initialstate?.ticketDetails
                                  ?.ticketPrice === "object"
                                  ? initialstate?.ticketDetails?.ticketPrice
                                      ?.value
                                  : initialstate?.ticketDetails?.ticketPrice
                              ) !==
                                Number(
                                  typeof state?.ticketDetails?.ticketPrice ===
                                    "object"
                                    ? state?.ticketDetails?.ticketPrice?.value
                                    : state?.ticketDetails?.ticketPrice
                                ) && "edited"
                            }`}
                          >
                            <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                              Ticket price
                            </label>
                            <PriceField
                              value={state?.ticketDetails?.ticketPrice?.value}
                              currenncySymbol={
                                state?.ticketDetails?.ticketPrice?.symbol
                                // orderOnOffer?.order_offer_team_currency
                              }
                              name="ticketPrice"
                              // label={"Ticket price"}
                              textFielClassName={`truncate`}
                              id={`ticketPriceorderoutoffer`}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        {/* CATEGORY */}
                        <div
                          className={`panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem] ${
                            initialstate?.ticketDetails?.category?.id !==
                              state?.ticketDetails?.category?.id && "edited"
                          }`}
                        >
                          <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                            Category
                          </label>
                          <DropDownField
                            value={state?.ticketDetails?.category}
                            name="category"
                            placeholder={
                              !state?.ticketDetails?.category ? "Category" : ""
                            }
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={eventData?.categories}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                    section: null,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* SECTION */}
                        <div
                          className={`panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem]  ${
                            initialstate?.ticketDetails?.section !==
                              state?.ticketDetails?.section && "edited"
                          }`}
                        >
                          <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                            Section/block
                          </label>
                          <DropDownField
                            value={state?.ticketDetails?.section}
                            name="section"
                            placeholder={
                              !state?.ticketDetails?.section
                                ? "Section/block"
                                : ""
                            }
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={state?.ticketDetails?.category?.sections}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* ROW */}
                        <div
                          className={`panelty-note lg2:w-1/4 md:w-1/2 w-full  flex flex-wrap md:gap-y-5 gap-y-[.9375rem]  `}
                        >
                          <div
                            className={`panelty-input md:w-1/2 w-full px-[.4375rem] ${
                              initialstate?.ticketDetails?.row !==
                                state?.ticketDetails?.row && "edited"
                            }`}
                          >
                            <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                              Row
                            </label>
                            <Textfield
                              name="row"
                              id={"row-new-order"}
                              value={state?.ticketDetails?.row}
                              // label={"Row"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                              placeholder="—"
                            />
                          </div>
                          <div
                            className={`panelty-input md:w-1/2 w-full px-[.4375rem]  ${
                              initialstate?.ticketDetails?.first_seat !==
                                state?.ticketDetails?.first_seat && "edited"
                            }`}
                          >
                            <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                              First seat
                            </label>
                            <Textfield
                              name="first_seat"
                              value={state?.ticketDetails?.first_seat}
                              id={"first-seat-new-order"}
                              // label={"First seat"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                              placeholder="—"
                            />
                          </div>
                        </div>

                        {/* SEAT */}

                        {/* Benefits  */}
                        <div
                          className={`panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem] ${
                            !_.isEqual(initialstate.benefits, state.benefits) &&
                            "edited"
                          }`}
                        >
                          <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                            Benefits
                          </label>
                          <MultiDropDownField
                            value={state?.benefits}
                            name="benefits"
                            placeholder=""
                            classNamePrefix={"form_multiDropDown"}
                            isClearable={true}
                            options={layout?.salesOptions?.benefits}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  [data?.target?.name]: data?.target?.value,
                                };
                              })
                            }
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) => {
                              handleOnSelection(
                                data,
                                "benefits",
                                layout?.salesOptions?.benefits,
                                visibleOptions
                              );
                            }}
                          />
                        </div>

                        {/* Restrictions */}
                        <div
                          className={`panelty-note lg:w-1/4 md:w-1/2 w-full  px-[.4375rem]  ${
                            !_.isEqual(
                              initialstate.restrictions,
                              state.restrictions
                            ) && "edited"
                          }`}
                        >
                          <label className="text-gray-500 leading-4 mb-1 text-sm12 block">
                            Restrictions
                          </label>
                          <MultiDropDownField
                            value={state?.restrictions}
                            name="restrictions"
                            placeholder=""
                            classNamePrefix={"form_multiDropDown"}
                            isClearable={true}
                            options={layout?.salesOptions?.restrictions}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  [data?.target?.name]: data?.target?.value,
                                };
                              })
                            }
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) => {
                              handleOnSelection(
                                data,
                                "restrictions",
                                layout?.salesOptions?.restrictions,
                                visibleOptions
                              );
                            }}
                          />
                        </div>

                        {/* Delivered by */}
                        <div className="panelty-note lg2:w-1/4 md:w-1/2 w-full  flex flex-wrap md:gap-y-5 gap-y-[.9375rem] ">
                          <div
                            className={`panelty-input md:w-1/2 w-full px-[.4375rem]  ${
                              moment(initialstate?.delivered_by).format() !==
                                moment(state?.delivered_by).format() && "edited"
                            }`}
                          >
                            <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                              Deliver by
                            </label>
                            <DatePickerField
                              placeholder={
                                state?.delivered_by ? undefined : "DD/MM/YYYY"
                              }
                              disabledKeyboardNavigation={false}
                              isDateRange={false}
                              // handleOnChange={handleCommonOnChange(setOrderFilters)}
                              format="dd/MM/yyyy"
                              name={"delivered_by"}
                              value={state?.delivered_by ?? undefined}
                              handleOnChange={(data: any) =>
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    [data?.target?.name]: data?.target?.value,
                                  };
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* FILTER HIGHLIGHTED  */}
                        <HighligtedFilter
                          data={replacementData}
                          bgColor="bg-white"
                          NoBorderBottom={true}
                          confirmedScreen={true}
                        />
                      </>
                    )}
                  </div>

                  {/* ORDER PENALTY - BLOCK */}
                  {(isDisabled ? orderPenaltyCheckbox : true) && (
                    <div
                      className={`order-panelty rounded border mb-5 anim-fade ${
                        orderPenaltyCheckbox &&
                        !isDisabled &&
                        "border-indigo-500"
                      }`}
                    >
                      {/* HEADER */}
                      {!isDisabled ? (
                        <Header
                          title="Order penalty"
                          className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                          paneltyToggle={true}
                          checkbox={true}
                          handleCheckbox={() => {
                            setOrderPenaltyCheckbox((pre) => !pre);
                          }}
                          checkboxValue={orderPenaltyCheckbox}
                        />
                      ) : (
                        // <Header
                        //   title="Order penalty"
                        //   className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                        //   paneltyToggle={true}
                        //   checkbox={true}
                        //   handleCheckbox={() => {
                        //     setOrderPenaltyCheckbox((pre) => !pre);
                        //   }}
                        //   checkboxValue={orderPenaltyCheckbox}
                        // />
                        <Header
                          title="Order penalty"
                          className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                        />
                      )}

                      {orderPenaltyCheckbox && (
                        <>
                          <SellarPenalityDetails
                            currenncy={SYMBOLS?.[orderOnOffer?.ticket_currency]}
                            inputValue={state?.penalty?.total}
                            amount={state?.available_amount}
                            checkedValue={state?.deduct_from_wallet}
                            onChange={(e: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  deduct_from_wallet: e.target.checked,
                                };
                              })
                            }
                            confirmation={layout?.orderOnOffer?.confirmed}
                            sellarName={orderOnOffer?.team_name}
                            deduct_from_wallet={state?.deduct_from_wallet}
                          />

                          {/* PENALITY INPUT FIELDS - BLOCK */}
                          <div
                            className={`${
                              !isDisabled ? "bg-white" : "bg-white"
                            } border-t panelty-block md:px-5 md:py-[.5625rem] min-h-[3.125rem] p-2.5 flex flex-wrap items-center bg-opacity-[7%] md:gap-x-[.9375rem] gap-2.5 border-b`}
                          >
                            {!isDisabled && (
                              <>
                                <div className="panelty-radio flex items-center gap-[.9375rem] font-medium md:w-auto w-full">
                                  <div className="inline-flex items-center font-medium rounded text-sm12 single_radio leading-5">
                                    <input
                                      type="radio"
                                      name="penaltyType"
                                      value="absolute"
                                      checked={
                                        state?.penalty?.penaltyType ===
                                        "absolute"
                                          ? true
                                          : false
                                      }
                                      id="absolutetype"
                                      onChange={(e: any) => {
                                        setState((current: any) => {
                                          return {
                                            ...current,
                                            penalty: {
                                              ...current?.penalty,
                                              penaltyType: "absolute",
                                              penaltyValue: "",
                                              total: 0,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                    <label htmlFor="absolutetype" className="">
                                      Amount (
                                      {SYMBOLS[orderOnOffer?.ticket_currency]})
                                    </label>
                                  </div>
                                  <div className="inline-flex items-center font-medium rounded text-sm12 single_radio leading-5">
                                    <input
                                      type="radio"
                                      name="penaltyType"
                                      value="relative"
                                      checked={
                                        state?.penalty?.penaltyType ===
                                        "relative"
                                          ? true
                                          : false
                                      }
                                      id="relativetype"
                                      onChange={(e: any) => {
                                        setState((current: any) => {
                                          return {
                                            ...current,
                                            penalty: {
                                              ...current?.penalty,
                                              penaltyType: "relative",
                                              penaltyValue: "",
                                              total: 0,
                                            },
                                          };
                                        });
                                      }}
                                    />
                                    <label htmlFor="relativetype" className="">
                                      Percentage (%)
                                    </label>
                                  </div>
                                </div>
                                <div className="panelty-input max-w-[10.1875rem]">
                                  {/* {JSON.stringify(orderOnOffer?.ticket_currency)} */}
                                  <PriceField
                                    textfieldType="number"
                                    value={state?.penalty?.penaltyValue}
                                    currenncySymbol={
                                      state?.penalty?.penaltyType === "absolute"
                                        ? orderOnOffer?.ticket_currency
                                        : "PER"
                                    }
                                    name="penaltyValue"
                                    // label="Penalty value"
                                    placeholder="Penalty value"
                                    textFielClassName={`truncate`}
                                    id={`penaltyValue`}
                                    handleOnChange={(data: any) => {
                                      setState((currentValue: any) => {
                                        const penaltyType =
                                          currentValue?.penalty?.penaltyType;
                                        const orderValue =
                                          orderOnOffer?.transaction_value;
                                        const penaltyValue =
                                          data?.target?.value?.value;

                                        let total: any = 0;

                                        if (penaltyType === "relative") {
                                          total = (
                                            (penaltyValue / 100) *
                                            orderValue
                                          ).toFixed(2);
                                        } else if (penaltyType === "absolute") {
                                          total = penaltyValue;
                                        }

                                        return {
                                          ...currentValue,
                                          deduct_from_wallet:
                                            Number(total) >
                                            Number(state?.available_amount)
                                              ? false
                                              : currentValue?.deduct_from_wallet,
                                          penalty: {
                                            ...currentValue?.penalty,
                                            penaltyValue,
                                            total,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <h5 className="text-sm13 font-semibold">
                              Penalty total:{" "}
                              {`${
                                SYMBOLS[orderOnOffer?.ticket_currency]
                              }${decimalNumber(state?.penalty?.total)}`}
                            </h5>
                          </div>

                          {/* PENALITY NOTES - BLOCK */}
                          <div className="panelty-note md:px-5 md:py-[1.125rem] md:pb-5 px-2.5 py-3.5 -mx-2.5">
                            <div className="md:w-1/2 w-full px-2.5">
                              <Label
                                asteriskColor={"text-gray-500"}
                                data="Penalty notes"
                                required={true}
                              />
                              {!isDisabled ? (
                                <input
                                  className={`bg-opacity-50 border 
                        font-medium transition bg-white focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded  px-2 block text-violet-800 resize-none py-1.5 leading-4 w-full min-h-[1.875rem]`}
                                  name="notes"
                                  value={state?.penalty?.notes}
                                  disabled={isDisabled}
                                  onChange={(e: any) => {
                                    setState((currentValue: any) => {
                                      return {
                                        ...currentValue,
                                        penalty: {
                                          ...currentValue?.penalty,
                                          notes: e?.target?.value,
                                        },
                                      };
                                    });
                                  }}
                                />
                              ) : (
                                <div className="bg-opacity-100  !border-gray-100/50 text-violet-800 font-medium transition bg-gray-100/50 focus:border-indigo-500 focus:bg-white outline-none peer whitespace-normal text-sm12 rounded  px-2 block  resize-none py-1.5 leading-4 w-full min-h-[1.875rem]">
                                  {state?.penalty?.notes}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {/* ORDER ADMINISTRATION - BLOCK */}
                  <div className="order-administration rounded border ">
                    <Header
                      title="Order admin (internal)"
                      className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />

                    {/* INPUT FIELDS  */}
                    <div className="panelty-status md:p-5 px-2.5 md:py-[.9375rem] md:pb-5  py-3.5 flex flex-wrap md:gap-y-5 gap-y-[.9375rem] -mx-[.4375rem]">
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full px-[.4375rem]">
                        <Label
                          asteriskColor={"text-gray-500"}
                          data="Internal order status"
                          required={true}
                        />
                        <DropDownField
                          value={state?.orderAdmistration?.orderStatus}
                          name="orderStatus"
                          placeholder={`${
                            state?.orderAdmistration?.orderStatus
                              ? ""
                              : "Internal order status"
                          }`}
                          menuPlacement="top"
                          minMenuHeight={200}
                          isDisabled={isDisabled}
                          isClearable={true}
                          options={convertToFormattedData(
                            layout?.salesOptions?.internal_order_statuses
                          )}
                          handleOnChange={(data: any) =>
                            setState((currentValue: any) => {
                              return {
                                ...currentValue,
                                orderAdmistration: {
                                  ...currentValue?.orderAdmistration,
                                  [data?.target?.name]: data?.target?.value,
                                },
                              };
                            })
                          }
                        />
                      </div>
                      <div className="panelty-note md:w-1/2 w-full px-[.4375rem]">
                        <Label
                          asteriskColor={"text-gray-500"}
                          data="Internal notes"
                          required={true}
                        />

                        {!isDisabled ? (
                          <input
                            className={`bg-opacity-50 border font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1.5 leading-4`}
                            name="internalNote"
                            value={state?.orderAdmistration?.internalNote}
                            disabled={isDisabled}
                            onChange={(e: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  orderAdmistration: {
                                    ...currentValue?.orderAdmistration,
                                    internalNote: e?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          <div
                            className=" text-violet-800 font-medium transition bg-gray-100/50  focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block resize-none py-1.5 leading-4 min-h-[1.875rem]"
                          >
                            {state?.orderAdmistration?.internalNote}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* ACTION BUTTONS */}
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-3 bg-white z-10 border-t">
              {!layout?.orderOnOffer?.confirmed &&
              !layout?.orderOnOffer?.disabledButton ? (
                <div className="l_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
                  <button
                    type="button"
                    className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                    onClick={() => {
                      layout.setOrderOnOffer(ORDER_ASSIGN_TO_NEW_TEAM);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`${
                      isActive?.reviewStatus
                        ? "hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 anim-fade"
                        : "bg-gray-100 text-gray-500 pointer-events-none"
                    } relative z-10    transition  rounded text-sm13 px-2.5 py-0.5`}
                    onClick={() => {
                      layout.setOrderOnOffer((currentValue: any) => {
                        return {
                          ...currentValue,
                          confirmed: true,
                        };
                      });
                    }}
                  >
                    Review
                  </button>
                </div>
              ) : (
                layout?.orderOnOffer?.confirmed &&
                !layout?.orderOnOffer?.disabledButton && (
                  <div className="l_btn gap-2.5 flex flex-nowrap items-center justify-end w-full overflow-auto whitespace-nowrap max-md:justify-start">
                    <span className="text-sm13">
                      Do you confirm all details are correct?
                    </span>
                    <button
                      type="button"
                      className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                      onClick={() => {
                        layout.setOrderOnOffer((currentValue: any) => {
                          return {
                            ...currentValue,
                            confirmed: false,
                          };
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className={` relative z-10   hover:text-white active: bg-green-600 text-white hover:bg-indigo-500 active:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5`}
                      onClick={() => {
                        layout.setOrderOnOffer((currentValue: any) => {
                          return {
                            ...currentValue,
                            // confirmed: false,
                            disabledButton: true,
                          };
                        });
                        setCustomNewOrderLoader(true);
                        // refetch();
                        refetchOfferOrder();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {customNewOrderLoader && (
        <ProgressBardLoader LoadingText="" secondCounter={1.5} />
      )}
    </React.Fragment>
  );
};

export default OrderOnOffer;
